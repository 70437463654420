<template>
    <div>
        <v-flex
            :xs6="$vuetify.breakpoint.mdAndUp"
            :xs12="$vuetify.breakpoint.smAndDown"
        >
            <form-element
                :label="
                    $t(
                        `applications.${$_.upperFirst(
                            type
                        ).toLowerCase()}-employment-status`
                    )
                "
                class="mb-1"
                required
            >
                <v-select
                    class="mt-2"
                    :placeholder="
                        $t('applications.choose-employment-status', { type })
                    "
                    :label="
                        $t('applications.choose-employment-status', { type })
                    "
                    solo
                    required
                    :rules="[
                        (v) =>
                            !!v ||
                            $t('applications.please-select-employment-status'),
                    ]"
                    :items="statusItems"
                    item-text="text"
                    item-value="status"
                    :value="statusData"
                    return-object
                    @change="statusChange"
                ></v-select>
            </form-element>
        </v-flex>
    </div>
</template>
<script>
export default {
    name: "select-employment-status",
    components: {},
    props: {
        type: {
            type: String,
            required: true,
        },
        status: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            items: [
                {
                    status: "employed",
                    text: this.$t("applications.employed"),
                    types: ["current", "previous"],
                },
                {
                    status: "self-employed",
                    text: this.$t("applications.self-employed"),
                    types: ["current"],
                },
                {
                    status: "retired",
                    text: this.$t("applications.retired"),
                    types: ["current"],
                },
                {
                    status: "social-security",
                    text: this.$t("applications.social-security-benefits"),
                    types: ["current"],
                },
                {
                    status: "disability",
                    text: this.$t("applications.disability"),
                    types: ["current"],
                },
                {
                    status: "unemployed",
                    text: this.$t("applications.unemployed"),
                    types: ["current"],
                },
                {
                    status: "none",
                    text: this.$t("applications.no-previous-employment"),
                    types: ["previous"],
                },
            ],
        };
    },
    computed: {
        statusData() {
            return this.status
                ? this.items.find((item) => item.status === this.status)
                : null;
        },
        statusItems() {
            return this.items.filter((item) => item.types.includes(this.type));
        },
    },

    methods: {
        statusChange(status) {
            this.$emit("update", status);
        },
    },
};
</script>

<style scoped>
</style>