var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-flex",
        {
          attrs: {
            xs6: _vm.$vuetify.breakpoint.mdAndUp,
            xs12: _vm.$vuetify.breakpoint.smAndDown
          }
        },
        [
          _c(
            "form-element",
            {
              staticClass: "mb-1",
              attrs: {
                label: _vm.$t(
                  "applications." +
                    _vm.$_.upperFirst(_vm.type).toLowerCase() +
                    "-employment-status"
                ),
                required: ""
              }
            },
            [
              _c("v-select", {
                staticClass: "mt-2",
                attrs: {
                  placeholder: _vm.$t("applications.choose-employment-status", {
                    type: _vm.type
                  }),
                  label: _vm.$t("applications.choose-employment-status", {
                    type: _vm.type
                  }),
                  solo: "",
                  required: "",
                  rules: [
                    function(v) {
                      return (
                        !!v ||
                        _vm.$t("applications.please-select-employment-status")
                      )
                    }
                  ],
                  items: _vm.statusItems,
                  "item-text": "text",
                  "item-value": "status",
                  value: _vm.statusData,
                  "return-object": ""
                },
                on: { change: _vm.statusChange }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }